import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import { useParams } from "react-router-dom";
import { signUp } from "aws-amplify/auth";
import { useNavigate } from "react-router";
import { ArrowRight } from "react-feather";
import validateConfirmPassword from "../../Utils/validateConfirmPassword";
import ErrorBanner from "../../components/ErrorBanner/ErrorBanner";
import "./ConsumerRegister.scss";
import ClipLoader from "react-spinners/ClipLoader";
import FormModalNotCloseable from "../../components/Modals/FormModalNotCloseable";
import CalendlyInfo from "../../components/Forms/CalendlyInfo/CalendlyInfo";
import consumerProfileService from "../../services/consumerProfile.service";

function ConsumerRegister() {
    let { consumerId, email } = useParams();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [inputDisabled, setInputDisabled] = useState(true);
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [infoModalText, setInfoModalText] = useState("");
    const [modalText, setModalText] = useState("");
    const [errorText, setErrorText] = useState("Something went wrong. Please try again.");

    const navigate = useNavigate();

    useEffect(() => {
        setShowErrorBanner(false);

        const fetchData = async () => {
            try {
                const consumerResponse = await consumerProfileService.getOne(email);
                if (!consumerResponse) {
                    setInfoModalText("Account Not Yet Created");
                    setModalText(
                        "You have not yet signed up for AdFi - please visit https://advisorfinder.com/app/singup to create profile",
                    );
                    setShowInfoModal(true);
                } else {
                    setInputDisabled(false);
                }
            } catch (error) {
                console.log(error);
                // setInfoModalText('Account Not Yet Created');
                // setModalText('You have not yet signed up for AdFi - please visit https://advisorfinder.com/app/singup to create profile');
                // setShowInfoModal(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [consumerId]);

    useEffect(() => {
        if (password === "" || confirmPassword === "") {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [password, confirmPassword]);

    async function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);
        const validate = validateConfirmPassword({ password, confirmPassword });

        if (Object.keys(validate).length === 0) {
            try {
                setDisabled(true);
                setShowErrorBanner(false);
                await signUpUser();
            } catch (error) {
                setShowErrorBanner(true);
                throw new Error(error);
            } finally {
                setLoading(false);
            }
        } else {
            setErrors(validate);
        }
        setLoading(false);
    }

    async function signUpUser() {
        setShowErrorBanner(false);
        try {
            const username = email;
            await signUp({
                username,
                password,
                options: {
                    userAttributes: {
                        "custom:id": consumerId,
                    },
                },
            });
            navigate(`/register/verify/Consumer/${email}/`, { replace: true });
        } catch (error) {
            console.log(error.name);
            if (error.name === "UsernameExistsException") {
                navigate(`/register/verify/Consumer/${email}/`, { replace: true });
            } else {
                console.log("error signing up:", error);
                setErrorText(error.message);
                setShowErrorBanner(true);
            }
        }
    }

    return (
        <>
            {loading && (
                <div className="spinnerContainer">
                    <ClipLoader color={"var(--adfi_blue500)"} loading={loading} size={100} />
                </div>
            )}
            <FormModalNotCloseable show={showInfoModal} title={infoModalText}>
                <CalendlyInfo text={modalText}></CalendlyInfo>
            </FormModalNotCloseable>
            <div className="page-container">
                <div className="register-container">
                    <div className="logo-container">
                        <a href="https://www.advisorfinder.com" rel="noopener">
                            <img
                                loading="lazy"
                                src="/app/AdFi-logo-name.svg"
                                alt="/AdFi-logo-name.svg"
                                className="register-logo"
                            />
                        </a>
                    </div>
                    <div className="form-container">
                        <img src="/app/sign-up-email.svg" alt="/sign-up-email.svg" />
                        <h1 className="register-header">Create your password</h1>

                        <p className="register-paragraph">
                            Already created an account?{" "}
                            <Link to={`/register/verify/Consumer/${email}/?resendCode=true`}>Verify email here</Link>
                        </p>
                        <form className="register-form" onSubmit={handleSubmit}>
                            <div className="input-container">
                                <input
                                    className="input full"
                                    type="email"
                                    id="email"
                                    disabled={true}
                                    placeholder={email}
                                />
                            </div>
                            <div className="input-container half-container">
                                <input
                                    className="input half"
                                    type="password"
                                    id="password"
                                    placeholder="Password"
                                    disabled={inputDisabled}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <input
                                    className="input half"
                                    type="password"
                                    id="confirmpassword"
                                    placeholder="Confirm password"
                                    disabled={inputDisabled}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                            <div style={{ color: "grey", opacity: "90%", textAlign: "left" }}>
                                Must consist of 8+ characters, upper and lowercase characters, 1 digit and 1 special
                                symbol
                            </div>
                            {errors.password && <p className="error-message">{errors.password}</p>}
                            <div>
                                <span>
                                    By selecting Create Account, I agree to the{" "}
                                    <a
                                        href="https://www.advisorfinder.com/terms-of-service"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Terms of Service
                                    </a>
                                </span>
                            </div>
                            <div className="input-container" style={{ marginBottom: "20px" }}>
                                <Button color="clear-button" type="submit" disabled={disabled}>
                                    Create Account
                                    <ArrowRight size={20} />
                                </Button>
                            </div>
                            <span>
                                If you are having trouble logging in, please visit our support center:{" "}
                                <a href="https://www.advisorfinder.com/help-support" target="_blank" rel="noreferrer">
                                    Help and Support
                                </a>
                            </span>
                        </form>
                        {showErrorBanner && (
                            <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errorText}</ErrorBanner>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConsumerRegister;
