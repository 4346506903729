import React, { useState, useEffect, useRef } from "react";
import styles from "./AssessmentResults.module.scss";
import { ArrowRight, ArrowLeft, RotateCcw } from "react-feather";
import consumerProfileService from "../../services/consumerProfile.service";
import Button from "../../components/Button/Button";
import useMixPanelEvent from "../../Utils/MixPanel";
import { Navigation, Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ClipLoader from "react-spinners/ClipLoader";
import AssessmentCard from "./AssessmentCard";
import { useNavigate } from "react-router-dom";
import useIsScreenWidth from "../../hooks/useIsScreenWidth";
import { HELP_WITH_FILTERS, INDUSTRY_MAPPINGS, LIFESTAGE_MAPPINGS } from "../Marketplace/Filters/FilterMappings";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AllSetAssessment from "../../components/Forms/AllSetAssessment/AllSetAssessment";
import FormModal from "../../components/Modals/FormModal";

function AssessmentResults() {
    const mixpanelTrack = useMixPanelEvent();
    const user = JSON.parse(localStorage.getItem("user"));
    const swiperRef = useRef();
    const [loading, setLoading] = useState(true);
    const [featuredAdvisors, setFeaturedAdvisors] = useState([]);
    const [selectedIndustryType, setSelectedIndustryType] = useState("");
    const [selectedService, setSelectedService] = useState([]);
    const [selectedLifeStage, setSelectedLifeStage] = useState("");
    const [sharedDetails, setSharedDetails] = useState();
    const [showAllSetModal, setShowAllSetModal] = useState(false);
    const [allSetModalAdvisorName, setAllSetModalAdvisorName] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        async function onPageLoad() {
            setLoading(true);
            const advisorResponse = await consumerProfileService.getassessmentResults(user.id);
            if (advisorResponse) {
                setFeaturedAdvisors(advisorResponse.advisors);
                setSharedDetails(advisorResponse.sharedInfoAdvisors);
                if (advisorResponse.sharedInfoAdvisors?.length === 1) {
                    const preSharedAdvisor = advisorResponse.advisors.filter(
                        (advisor) => advisor.professionalId === advisorResponse.sharedInfoAdvisors[0],
                    );
                    setAllSetModalAdvisorName(`${preSharedAdvisor[0].firstName} ${preSharedAdvisor[0].lastName}`);
                }
                setSelectedIndustryType(
                    INDUSTRY_MAPPINGS.find(
                        (item) => item.key === advisorResponse.assessmentQuestions?.q2?.industryTypeIds?.at(0),
                    ).value,
                );
                setSelectedLifeStage(
                    LIFESTAGE_MAPPINGS.find(
                        (item) => item.key === advisorResponse.assessmentQuestions?.q3?.lifeStageIds?.at(0),
                    ).value,
                );
                HELP_WITH_FILTERS.forEach((item) => {
                    if (advisorResponse.assessmentQuestions?.q1?.helpWithIds?.includes(item.key)) {
                        return setSelectedService((selectedService) => [...selectedService, item.value + ", "]);
                    }
                });

                advisorResponse.advisors?.forEach((advisor) => {
                    mixpanelTrack("Advisor Displayed", "Data", {
                        "Advisor Name": `${advisor.firstName} ${advisor.lastName} - ${advisor.professionalId}`,
                        Screen: "Assessment Results",
                    });
                });
            }
            setLoading(false);
        }
        onPageLoad();
    }, [user.id]);

    useEffect(() => {
        const checkFirstVisit = () => {
            if (localStorage.getItem("was_visited")) {
                return;
            }
            setShowAllSetModal(true);
            localStorage.setItem("was_visited", 1);
        };

        checkFirstVisit();
    }, []);

    return (
        <>
            {loading && (
                <div className="spinnerContainer">
                    <ClipLoader color={"var(--adfi_blue500)"} loading={loading} size={100} />
                </div>
            )}
            {showAllSetModal && (
                <FormModal onClose={() => setShowAllSetModal(false)} show={showAllSetModal}>
                    <AllSetAssessment
                        preSharedAdvisorName={allSetModalAdvisorName}
                        setShowAllSetModal={setShowAllSetModal}
                    />
                </FormModal>
            )}
            <div className={styles.backgroundViewHeight}>
                <div className={styles.pageContainer}>
                    <div className={styles.contentContainer}>
                        {useIsScreenWidth(800) && (
                            <div className={styles.assessmentResultsHeader}>
                                {!loading && (
                                    <>
                                        <h1>
                                            <span>Your Advisor Connections</span>
                                        </h1>

                                        <span
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                gap: "10px",
                                                textAlign: "center",
                                                width: "80%",
                                            }}
                                        >
                                            <h2>
                                                Based on your search for advisors who understand these industries:{" "}
                                                <b>{selectedIndustryType}</b>, who can help with:{" "}
                                                <b>{selectedService}</b>
                                                and work with clients in these stages of life:{" "}
                                                <b>{selectedLifeStage}</b>, the following professionals fit your
                                                criteria. You can review each profile to learn more about the advisor.
                                            </h2>
                                        </span>
                                    </>
                                )}
                            </div>
                        )}
                        {!useIsScreenWidth(800) && (
                            <div className={styles.assessmentResultsHeader}>
                                {!loading && (
                                    <div>
                                        <h1 style={{ marginBottom: "10px" }}>Your Advisor Connections</h1>
                                        <h2 style={{ marginBottom: "10px" }}>
                                            Based on your search for advisors who understand these industries:{" "}
                                            <b>{selectedIndustryType}</b>, who can help with: <b>{selectedService}</b>
                                            and work with clients in these stages of life: <b>{selectedLifeStage}</b>,
                                            the following professionals fit your criteria. You can review each profile
                                            to learn more about the advisor.
                                        </h2>
                                        <span
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                gap: "10px",
                                                alignItems: "center",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <b>Swipe to view all results.</b>
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className={styles.swiperWithButtons}>
                            {useIsScreenWidth(800) && (
                                <div className={styles.swiperButtonAligner}>
                                    {!loading && (
                                        <Button
                                            onClick={() => swiperRef.current.slidePrev()}
                                            className="white"
                                            color="clear-button"
                                            circle={true}
                                        >
                                            <ArrowLeft size={20} />
                                        </Button>
                                    )}
                                </div>
                            )}
                            {!loading && (
                                <Swiper
                                    modules={[Navigation, Pagination, A11y]}
                                    slidesPerView={"auto"}
                                    className={styles.mySwiper}
                                    style={{ padding: "5px" }}
                                    spaceBetween={20}
                                    breakpoints={{
                                        800: {
                                            slidesPerView: 1.1,
                                        },
                                        900: {
                                            slidesPerView: 1.2,
                                        },
                                        1000: {
                                            slidesPerView: 1.5,
                                        },
                                        1200: {
                                            slidesPerView: 2,
                                        },
                                        1500: {
                                            slidesPerView: 3,
                                        },
                                        1800: {
                                            slidesPerView: 3.5,
                                        },
                                    }}
                                    onSwiper={(swiper) => {
                                        swiperRef.current = swiper;
                                    }}
                                >
                                    {featuredAdvisors.map((advisor) => {
                                        return (
                                            <SwiperSlide key={advisor.professionalId}>
                                                <AssessmentCard
                                                    advisor={advisor}
                                                    sharedDetails={sharedDetails}
                                                    setLoading={setLoading}
                                                    onClick={() =>
                                                        mixpanelTrack("Advisor Profile Clicked", "Data", {
                                                            "Advisor Name": `${advisor.firstName} ${advisor.lastName} - ${advisor.professionalId}`,
                                                            Screen: "Assessment Results",
                                                        })
                                                    }
                                                />
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            )}
                            {useIsScreenWidth(800) && (
                                <div className={styles.swiperButtonAligner}>
                                    {!loading && (
                                        <Button
                                            onClick={() => swiperRef.current.slideNext()}
                                            className="white"
                                            color="clear-button"
                                            circle={true}
                                        >
                                            <ArrowRight size={20} />
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>

                        {/* Mobile Swiper Buttons */}
                        <div className={styles.mobileSwiperButtons}>
                            {!useIsScreenWidth(800) && (
                                <div style={{ padding: "15px" }}>
                                    {!loading && (
                                        <Button
                                            onClick={() => swiperRef.current.slidePrev()}
                                            className="white"
                                            color="clear-button"
                                            circle={true}
                                        >
                                            <ArrowLeft size={20} />
                                        </Button>
                                    )}
                                </div>
                            )}
                            {!useIsScreenWidth(800) && (
                                <div style={{ padding: "15px" }}>
                                    {!loading && (
                                        <Button
                                            onClick={() => swiperRef.current.slideNext()}
                                            className="white"
                                            color="clear-button"
                                            circle={true}
                                        >
                                            <ArrowRight size={20} />
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>

                        {!loading && (
                            <div style={{ width: "100%", marginTop: "20px", marginBottom: "40px" }}>
                                <Button
                                    color="clear-button white"
                                    onClick={() => navigate(`/assessment`, { replace: true })}
                                >
                                    <RotateCcw size={20} /> Update My Assessment
                                </Button>
                            </div>
                        )}

                        {useIsScreenWidth(800) && !loading && (
                            <div className={styles.marketplaceFooter}>
                                <span>
                                    <b>Head to the marketplace</b> to truly customize every part of your search.
                                </span>{" "}
                                <Button
                                    color="gradient-button"
                                    size="thin"
                                    onClick={() => navigate(`/marketplace`, { replace: true })}
                                >
                                    View all advisors
                                    <ArrowRight size={20} />
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                {!useIsScreenWidth(800) && !loading && (
                    <div className={styles.marketplaceFooterMobile}>
                        <span>
                            <b>Head to the marketplace</b> to truly customize every part of your search to find your
                            next financial advisor.
                        </span>{" "}
                        <Button
                            color="gradient-button"
                            size="thin"
                            onClick={() => navigate(`/marketplace`, { replace: true })}
                        >
                            View all advisors
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
}

export default AssessmentResults;
