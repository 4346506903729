import React from "react";
import styles from "./AllSetAssessment.module.scss";
import { Check } from "react-feather";
import Button from "../../Button/Button";

export default function AllSetAssessment(props) {
    const { preSharedAdvisorName, setShowAllSetModal } = props;

    return (
        <>
            <div className={styles.formContainer}>
                <h1 style={{ fontSize: "40px", textAlign: "center" }}>All Set!</h1>
                <div className={styles.allSetContentContainer}>
                    <div className={styles.bottomBorder} style={{ padding: "20px 0px" }}>
                        <h2 className={styles.listHeader}>1. You're connected!</h2>
                        <span>
                            Your assessment submissions have been shared with {preSharedAdvisorName} who's offerings
                            most closely align with your needs. This advisor may reach out to you to discuss next steps!
                        </span>
                    </div>
                    <div className={styles.bottomBorder} style={{ padding: "20px 0px" }}>
                        <h2 className={styles.listHeader}>2. View advisor results</h2>
                        <span>
                            Browse advisors from the connections list and select one or more to learn about their
                            offerings on their advisor profile!
                        </span>
                    </div>
                    <div style={{ marginBottom: "40px", padding: "20px 0px" }}>
                        <h2 className={styles.listHeader}>3. Connect with advisors</h2>
                        <span>
                            Although some advisors have already been notified of your needs, selecting the "Connect with
                            Advisor" button from the results or using the "Contact" button on any advisor profile will
                            indicate to that advisor that you are ready to setup your first meeting!
                        </span>
                    </div>
                    <Button onClick={() => setShowAllSetModal(false)}>
                        <Check size={20} />
                        View advisors
                    </Button>
                </div>
            </div>
        </>
    );
}
